import {mapActions, mapGetters, mapMutations} from "vuex";
import {maxLength, minLength, required, email, helpers} from "vuelidate/lib/validators";
const AZazValidator = helpers.regex('AZaz', /^[a-z\d .,-_]*$/i);
import {validationMixin} from "vuelidate";
import mainSelect from '@/components/main-select/index.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import Vue from "vue";
Vue.component('v-select', vSelect)
export default {
  mixin: [validationMixin],
  name: "add-address",
  data() {
    return {
      payload: {
        country: {},
        region: {},
        district: '',
        city: '',
        street: '',
        house: '',
        flat: '',
        index: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        is_main: false,
        id: '',
        inn:''
      },
      validationErrors: {},
      isMain: false,
      disable: false,
    }
  },

  created() {
    this.fetchCountriesList()
    this.setData()
    this.fetchRegionsList(this.payload.country.id)
  },
  validations() {
    if (this.payload.country.title === 'Russian Federation') {
      return {
        payload: {
          country: {
            required,
          },
          region: {
            required,
          },
          district: {
            AZazValidator,
            // required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          city: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          street: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          index: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          first_name: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          last_name: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          phone: {
            required,
            maxLength: maxLength(15),
          },
          inn: {
            required,
            maxLength: maxLength(12),
            minLength: minLength(12),
          },
          email: {
            email,
            required,
            minLength: minLength(3),
            maxLength: maxLength(99),
          },
          house:{
            required
          }
        },
      }
    } else {
      return {
        payload: {
          country: {
            required,
          },
          region: {
            required,
          },
          district: {
            AZazValidator,
            // required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          city: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          street: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          index: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          first_name: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          last_name: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          phone: {
            required,
            maxLength: maxLength(15),
          },
          email: {
            email,
            required,
            minLength: minLength(3),
            maxLength: maxLength(99),
          },
          house:{
            required
          }
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      userInfo: `order/userInfo`,

      countriesList: 'profile/countriesList',
      regionsList: 'profile/regionsList',
    }),
    houseErrors() {
      let error = [];
      if (!this.$v.payload.house.$dirty) {
        return error;
      }
      if (!this.$v.payload.house.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.house) {
        this.validationErrors.house.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    innErrors() {
      let error = [];
      if (!this.$v.payload.inn.$dirty) {
        return error;
      }
      if (!this.$v.payload.inn.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.inn.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 12));
      }
      if (!this.$v.payload.inn.minLength) {
        error.push(this.$t('validationMin').replace(':count', 12));
      }
      if (this.validationErrors.inn) {
        this.validationErrors.inn.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    nameErrors() {
      let error = [];
      if (!this.$v.payload.first_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.first_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.first_name.AZazValidator) {
        error.push(this.$t('validationEnglish'))
      }
      if (!this.$v.payload.first_name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (!this.$v.payload.first_name.minLength) {
        error.push(this.$t('validationMin').replace(':count', 1));
      }
      if (this.validationErrors.first_name) {
        this.validationErrors.first_name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    secondNameErrors() {
      let error = [];
      if (!this.$v.payload.last_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.last_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.last_name.AZazValidator) {
        error.push(this.$t('validationEnglish'))
      }
      if (!this.$v.payload.last_name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (!this.$v.payload.last_name.minLength) {
        error.push(this.$t('validationMin').replace(':count', 1));
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    streetErrors() {
      let error = [];
      if (!this.$v.payload.street.$dirty) {
        return error;
      }
      if (!this.$v.payload.street.minLength) {
        error.push(this.$t('validationMin').replace(':count', 1));
      }
      if (!this.$v.payload.street.AZazValidator) {
        error.push(this.$t('validationEnglish'))
      }
      if (!this.$v.payload.street.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (!this.$v.payload.street.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.street) {
        this.validationErrors.street.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    countryErrors() {
      let error = [];
      if (!this.$v.payload.country.$dirty) {
        return error;
      }
      if (!this.$v.payload.country.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.country) {
        this.validationErrors.country.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    regionErrors() {
      let error = [];
      if (!this.$v.payload.region.$dirty) {
        return error;
      }
      if (!this.$v.payload.region.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.region) {
        this.validationErrors.region.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    districtErrors() {
      let error = [];
      if (!this.$v.payload.district.$dirty) {
        return error;
      }
      if (!this.$v.payload.district.minLength) {
        error.push(this.$t('validationMin').replace(':count', 1));
      }
      if (!this.$v.payload.district.AZazValidator) {
        error.push(this.$t('validationEnglish'))
      }
      if (!this.$v.payload.district.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      // if (!this.$v.payload.district.required) {
      //   error.push(this.$t('validationRequired'));
      // }
      if (this.validationErrors.district) {
        this.validationErrors.district.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    cityErrors() {
      let error = [];
      if (!this.$v.payload.city.$dirty) {
        return error;
      }
      if (!this.$v.payload.city.minLength) {
        error.push(this.$t('validationMin').replace(':count', 1));
      }
      if (!this.$v.payload.city.AZazValidator) {
        error.push(this.$t('validationEnglish'))
      }
      if (!this.$v.payload.city.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (!this.$v.payload.city.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.city) {
        this.validationErrors.city.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    indexErrors() {
      let error = [];
      if (!this.$v.payload.index.$dirty) {
        return error;
      }
      if (!this.$v.payload.index.minLength) {
        error.push(this.$t('validationMin').replace(':count', 1));
      }
      if (!this.$v.payload.index.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (!this.$v.payload.index.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.index) {
        this.validationErrors.index.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 30));
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.phone.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 15));
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  components: {
    mainSelect
  },
  methods: {
    ...mapMutations({
      changePopupAddAddress: `profile/CHANGE_POPUP_ADD_ADDRESS`,
      changeEditAddress: `order/CHANGE_EDIT_ADDRESS_POPUP`,
    }),
    ...mapActions({
      addAddress: `profile/ADD_USER_ADDRESS`,
      fetchUser: `profile/FETCH_USER_DATA`,
      editUserAddress:`profile/EDIT_USER_ADDRESS`,

      fetchCountriesList: `profile/FETCH_COUNTRIES_LIST`,
      fetchRegionsList: `profile/FETCH_REGIONS_LIST`,
    }),
    setData() {
      this.payload.country.title = this.userInfo.country
      this.payload.country.id = this.userInfo.country_id
      this.payload.region.title = this.userInfo.region
      this.payload.region.id = this.userInfo.region_id
      this.payload.district = this.userInfo.district
      this.payload.city = this.userInfo.city
      this.payload.street = this.userInfo.street
      this.payload.house = this.userInfo.house
      this.payload.flat = this.userInfo.flat
      this.payload.index = this.userInfo.index
      this.payload.first_name = this.userInfo.first_name
      this.payload.last_name = this.userInfo.last_name
      this.payload.phone = this.userInfo.phone
      this.payload.email = this.userInfo.email
      this.payload.is_main = this.userInfo.is_main
      this.payload.inn = this.userInfo.inn
      this.payload.id = this.userInfo.id
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.disable = true
        let obj = {}
        obj.id = this.payload.id
        obj.country_id = this.payload.country.id
        obj.region_id = this.payload.region.id
        obj.district = this.payload.district
        obj.city = this.payload.city
        obj.street = this.payload.street
        obj.house = this.payload.house
        obj.flat = this.payload.flat
        obj.index = this.payload.index
        obj.first_name = this.payload.first_name
        obj.last_name = this.payload.last_name
        obj.phone = this.payload.phone
        obj.email = this.payload.email
        obj.inn = this.payload.inn
        obj.is_main = this.payload.is_main
        this.disable = true
        this.editUserAddress(obj).then(()=>{
          this.$toasted.success(this.$t('youAddressEdit'));
          this.fetchUser()
          setTimeout(()=>{
            this.disable = false
          },400)
          this.changeEditAddress(false)
        }).catch(error => {
          if (error.response.status === 422) {
            console.log(error.response)
          }
          setTimeout(()=>{
            this.disable = false
          },400)
        });
      }
    },
    changeRegion(){
      this.payload.region.title = ''
      this.fetchRegionsList(this.payload.country.id).then(()=>{
        if(this.regionsList.length === 1){
          this.payload.region = this.regionsList[0]
        }
      })
    },
  }
}